#loading-layout{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; */
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 45%;
    margin-bottom: 5%;
}

.heart-img {
    max-width: 150px;
    max-height: 150px;
    padding-bottom: 20px;
}

.loading-style {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#ecg-progress-bar {
    padding-bottom: 20px;
}

#lower-section {
    padding-top: 30px;
}

#secret-button-load {
    color: white;
    fill: white;
    opacity: 0;
    position: relative;
    left: 0;
    position:absolute; 
    margin-left:-50px;
    left:50%;
    width:100px;
    bottom:0px;

    /* position: absolute;
    right: 50%;
    bottom: 100%; */
}