.home {
    /* position: relative; */
    /* display: table; */
    /* align-items: center; */
    /* justify-content: center;
    flex-direction: column; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 45%;
    margin-bottom: 5%;
}

.DMILogo {
    /* max-width: 400px;
    max-height: 200px; */
    padding-bottom: 20px;
    position: flex;
    top:50%;
    bottom:50%;

    padding-top:30px;
    
    /* With HQ Logo */
    /* position: absolute;
    top: 50%;
    height: 50%;
    width: 50%;
    margin: 60% 0 0 -20%; */

    /* For the DMILogo */
    /* position: absolute;
    top: 50%;
    left: 50%;
    height: 45%;
    width: 80%;
    margin: 50% 0 0 -39%; */

    /* padding-bottom: 10px; */

    /* position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    width: 60%;
    margin: 50% 0 0 -28%; */

    /* height: 0; */
    /* position: relative; */
    /* display: block;
    margin-left: auto;
    margin-right: auto */
   
}

.homeButton {
    position: flex;
    /* top: 0px; */
    left: 50%;
    right:50%;
    /* height: 30%; */
    /* width: 30%; */
    /* margin: 90% 0 0 -25%; */
    
    /* display:flex;
    justify-content:center;
    align-items:center; */
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    /* margin-top: '25%'; */

}