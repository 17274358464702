#instruct-layout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
}

/* .instrucitons{
    position: absolute;
    align-items: left;
    text-align: left;
} */