#rpg-results-layout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 30%;
    margin-bottom: 5%;
}

#rpg-circle {
    width: 100px;
    height: 100px;
    background: #228b22;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

#rpg-index {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 30px;
}

#rpg-results-middle-text {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 100px;
}

