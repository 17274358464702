#connect-layout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 5%;
}

.bluetooth-img {
    max-width: 200px;
    max-height: 200px;
    padding-bottom: 20px;
}

.disabled-link {
    pointer-events: none;
}

.enabled-link{
    pointer-events: auto;
}