#results-layout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 5%;
}

#circle {
    width: 100px;
    height: 100px;
    background: #DC3444;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

#index {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 30px;
}

#results-middle-text {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

